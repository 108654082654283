import {
  applyMiddleware,
  combineReducers,
  legacy_createStore,
  compose,
} from "redux";
import thunk from "redux-thunk";
import MembershipReducer from "./membershipReducer";
import HallManagementReducer from "./hallManagementReducer";
import LoungeManagementReducer from "./loungeManagementReducer";
import EquipmentReducer from "./equipmentReducer";
import EventReducer from "./eventReducer";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

const rootReducer = combineReducers({
  MembershipReducer,
  HallManagementReducer,
  LoungeManagementReducer,
  EquipmentReducer,
  EventReducer,
});

export const store = legacy_createStore(
  rootReducer,
  compose(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

type DispatchFunc = () => AppDispatch;
export const useAppDispatch: DispatchFunc = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
