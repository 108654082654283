import { Lounge } from "../utils/types";
import { actionTypes } from "./action.types";

export interface LoungeManagementState {
  loading: boolean;
  error: boolean;
  lounges: Lounge[];
  loungeById: Lounge | null;
}

const initialState: LoungeManagementState = {
  loading: false,
  error: false,
  lounges: [],
  loungeById: null,
};

const LoungeManagementReducer = (
  state = initialState,
  action: { type: string; payload?: any }
) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.GETLOUNGE_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.GETLOUNGE_SUCCESS:
      return { ...state, loading: false, lounges: payload as Lounge[] };
    case actionTypes.GETLOUNGE_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.GETLOUNGEBYID_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.GETLOUNGEBYID_SUCCESS:
      return { ...state, loading: false, loungeById: payload as Lounge };
    case actionTypes.GETLOUNGEBYID_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.ADDLOUNGE_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.ADDLOUNGE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        lounges: [payload as Lounge, ...state?.lounges],
      };
    case actionTypes.ADDLOUNGE_ERROR:
      return { ...state, loading: false, error: true };
    case actionTypes.UPDATELOUNGE_LOADING:
      return { ...state, loading: true, error: false };
    case actionTypes.UPDATELOUNGE_SUCCESS:
      return { ...state, loading: false, loungeById: payload as Lounge };
    case actionTypes.UPDATELOUNGE_ERROR:
      return { ...state, loading: false, error: true };

    case actionTypes.DELETELOUNGE_SUCCESS: {
      let updatedLoungesByLoungeId: Lounge[] = state?.lounges.filter((item) => {
        return item.id !== payload.id;
      });
      return { ...state, lounges: updatedLoungesByLoungeId as Lounge[] };
    }
    case actionTypes.DELETELOUNGE_ERROR:
      return { ...state, loading: false, error: true };

    default:
      return state;
  }
};

export default LoungeManagementReducer;
