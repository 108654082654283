import React, { useState } from "react";
import {
    FormControl,
    Grid,
    InputLabel,
    Select,
    MenuItem,
    SelectChangeEvent,
    Box,
    Card
  } from "@mui/material";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import MDInput from "../../components/MDInput";
import MDBox from "../../components/MDBox";
import axios from "axios";
import { useSnackbar } from "notistack";

const ReportManagement: React.FC = () => {
  const [reportFrom, setReportFrom] = useState("");
  const [reportTill, setReportTill] = useState("");
  const [filter, setFilter] = useState("");
  const token = localStorage.getItem("token");
  const { enqueueSnackbar } = useSnackbar();

  const widthStyle = {
    width: "45%",
    "@media (max-width: 600px)": {
      width: "100%",
    },
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "report_from") {
      setReportFrom(value);
    } else if (name === "report_till") {
      setReportTill(value);
    }
  };

  const handleFilterChange = (e: SelectChangeEvent<string>) => {
    setFilter(e.target.value);
  };

  const handleSubmit = async () => {
    if (!filter) {
      enqueueSnackbar("Please select a Filter!!", { variant: "error" });
      return;
    }
  
    const formattedFromDate = `${reportFrom} 00:00:00`;
    const formattedTillDate = `${reportTill} 00:00:00`;
  
    const data = {
      filter,
      fromDate: formattedFromDate,
      toDate: formattedTillDate,
    };
  
    try {
      const response = await axios.post(
        "https://api.pixacci.com/booking/filter",
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          responseType: "blob",
        }
      );
  
      if (response.status === 200) {
        const blob = new Blob([response.data]);
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `report_${filter}_${formattedFromDate}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      } else {
        enqueueSnackbar("Unexpected error occurred", { variant: "error" });
      }
    } catch (error: any) {
      if (error.response && error.response.data instanceof Blob) {
        const reader = new FileReader();
        reader.onload = function () {
          const text = reader.result as string;
          const jsonResponse = JSON.parse(text);
          enqueueSnackbar(jsonResponse.message, { variant: "error" }); 
        };
        reader.readAsText(error.response.data);
      } else {
        enqueueSnackbar("An unexpected error occurred", { variant: "error" });
        console.error("Error generating report:", error);
      }
    }
  };
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Box pt={6} pb={3}>
        <Grid container spacing={3} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <Box
                mx={2}
                mt={-3}
                py={3}
                px={2}
                sx={{
                  bgcolor: 'info.main',
                  borderRadius: '12px',
                  boxShadow: '0 4px 20px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255,152,0,0.4)',
                  display: 'flex',
                  justifyContent: 'space-between'
                }}
              >
                <MDTypography variant="h5" {...({} as any)} fontWeight="medium" color="white" mt={1}>
                  Report
                </MDTypography>
              </Box>
              <MDBox
                mx={2}
                mt={3}
                py={3}
                p={4}
                mb={2}
                borderRadius="lg"
                {...({} as any)}
              >
                <Grid container direction="row" justifyContent="space-between">
                  <Grid container direction="column" gap={3} pt={2} sx={widthStyle}>
                    <MDTypography variant="h6" pb={2} {...({} as any)}>
                      From
                    </MDTypography>
                    <MDInput
                      required
                      type="date"
                      name="report_from"
                      value={reportFrom}
                      onChange={handleDateChange}
                      {...({} as any)}
                    />
                  </Grid>
                  <Grid container direction="column" gap={3} pt={2} sx={widthStyle}>
                    <MDTypography variant="h6" pb={2} {...({} as any)}>
                      To
                    </MDTypography>
                    <MDInput
                      required
                      type="date"
                      name="report_till"
                      value={reportTill}
                      onChange={handleDateChange}
                      {...({} as any)}
                    />
                  </Grid>
                </Grid>
                <Grid container justifyContent="center" pt={3}>
                  <FormControl sx={widthStyle}>
                    <InputLabel>Filter</InputLabel>
                    <Select
                      labelId="filter-label"
                      value={filter}
                      onChange={handleFilterChange}
                      style={{ height: "45px" }}
                    >
                      <MenuItem value="">
                      </MenuItem>
                      <MenuItem value="hall">Hall</MenuItem>
                      <MenuItem value="booking">Bookings</MenuItem>
                      <MenuItem value="membership">Membership</MenuItem>
                      <MenuItem value="event">Event</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
          <Grid container direction="row" justifyContent="flex-end" p={2} pb={0.5}>
            <MDButton variant="gradient" color="info" onClick={handleSubmit} {...({} as any)}>
              Generate
            </MDButton>
          </Grid>
        </Grid>
      </Box>
    </DashboardLayout>

  );
}

export default ReportManagement;
