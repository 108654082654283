// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";

// Material Dashboard 2 React example components
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import DataTable from "../../examples/Tables/DataTable";

// Data
import { useEffect, useState } from "react";
import { DeleteLoungeById, GetLounge } from "../../Redux/action";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import Tooltip from "@mui/material/Tooltip";
import { useAppDispatch, useAppSelector, RootState } from "../../Redux/store";
import MDBox from "../../components/MDBox";
import MDAvatar from "../../components/MDAvatar";
import MDTypography from "../../components/MDTypography";
import MDBadge from "../../components/MDBadge";
import MDButton from "../../components/MDButton";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";
import { useSnackbar } from "notistack";

const LoungeManagement: React.FC = () => {
  const Author = ({
    image,
    name,
    capacity,
  }: {
    image: string;
    name: string;
    capacity: number;
  }) => (
    <MDBox {...({} as any)} display="flex" alignItems="center" lineHeight={1}>
      <MDAvatar
        {...({} as any)}
        src={image}
        //   name={name}
        size="sm"
      />
      <MDBox {...({} as any)} ml={2} lineHeight={1}>
        <MDTypography
          {...({} as any)}
          display="block"
          variant="button"
          fontWeight="medium"
        >
          {name}
        </MDTypography>
        <MDTypography {...({} as any)} variant="caption">
          having capacity of {capacity}
        </MDTypography>
      </MDBox>
    </MDBox>
  );

  const Job = ({ price, discount }: { price: number; discount: number }) => (
    <MDBox {...({} as any)} lineHeight={1} textAlign="left">
      <MDTypography
        {...({} as any)}
        display="block"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {price}
      </MDTypography>
      <MDTypography {...({} as any)} variant="caption">
        with discount of Rs. {discount}
      </MDTypography>
    </MDBox>
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const { lounges, loading } = useAppSelector(
    (store: RootState) => store.LoungeManagementReducer
  );
  const loungesData = lounges || [];
  // console.log("loungesData", loungesData);

  const handleEditClick = (id: number | undefined) => {
    if (id) {
      navigate("/lounge/edit/", { state: { type: "edit", id: id } });
    }
  };

  const handleClickOpen = (id: number) => {
    setDeleteId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDeleteClick = async (id: number) => {
    try {
      await dispatch<any>(DeleteLoungeById(deleteId!));
      dispatch<any>(GetLounge());
      handleClose();
      enqueueSnackbar("Lounge is deleted successfully.", {
        variant: "success",
      });
    } catch (error) {
      console.error("Error deleting Lounge:", error);
    }
  };

  const columns = [
    { Header: "lounge name", accessor: "lounge name", align: "left" },
    { Header: "address", accessor: "address", align: "left" },
    { Header: "booking price", accessor: "booking price", align: "left" },
    { Header: "booking availability", accessor: "status", align: "center" },
    {
      Header: "applicable deposit",
      accessor: "applicable deposit",
      align: "center",
    },
    { Header: "action", accessor: "action", align: "center" },
  ];

  const rows = loungesData?.map((lounge) => ({
    "lounge name": (
      <Author
        image={
          "https://klr.ac.in/wp-content/uploads/2015/11/dummy-user-1-200x200.jpg"
        }
        name={`${lounge?.name}`}
        capacity={lounge.capacity}
      />
    ),
    address: (
      <MDTypography
        {...({} as any)}
        component="a"
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {lounge?.address}
      </MDTypography>
    ),
    "booking price": (
      <Job price={lounge?.booking_price_hour} discount={lounge?.current_discount} />
    ),
    status: (
      <MDBox {...({} as any)} ml={-1}>
        <MDBadge
          {...({} as any)}
          badgeContent={lounge?.booking_from}
          color={"success"}
          variant="gradient"
          size="sm"
        />
        <MDBadge
          {...({} as any)}
          color={"success"}
          badgeContent={lounge?.booking_till}
          variant="gradient"
          size="sm"
        />
      </MDBox>
    ),
    "applicable deposit": (
      <MDTypography
        {...({} as any)}
        href="#"
        variant="caption"
        color="text"
        fontWeight="medium"
      >
        {lounge?.applicable_deposit}
      </MDTypography>
    ),
    action: (
      <>
        <MDBadge
          {...({} as any)}
          badgeContent={"Edit"}
          sx={{ cursor: "pointer" }}
          fontWeight="medium"
          onClick={() => handleEditClick(lounge?.id)}
        />
        <MDBadge
          {...({} as any)}
          color={"dark"}
          badgeContent={"Delete"}
          sx={{ cursor: "pointer" }}
          fontWeight="medium"
          onClick={() => {
            if (lounge?.id) handleDeleteClick(lounge?.id);
          }}
        />
      </>
    ),
  }));

  const handleGetLounges = async () => {
    try {
      await dispatch<any>(GetLounge());
    } catch (error) {
      console.error("Error fetching lounge data:", error);
    }
  };

  useEffect(() => {
    handleGetLounges();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox {...({} as any)} pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <MDBox
                {...({} as any)}
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography {...({} as any)} variant="h6" color="white">
                  Lounge Management
                </MDTypography>
                <MDBox {...({} as any)} className="ticket_btnsHeaderCont">
                  <Tooltip title="Add a new Lounge">
                    <MDButton
                      {...({} as any)}
                      onClick={() => {
                        navigate("/lounge/add", { state: { type: "add" } });
                      }}
                      variant="outlined"
                      color="white"
                      startIcon={<AddHomeWorkIcon />}
                    >
                      Lounge
                    </MDButton>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <MDBox {...({} as any)} pt={3}>
                {loungesData && !loading ? (
                  loungesData?.length > 0 ? (
                    <DataTable
                      table={{ columns, rows }}
                      isSorted={false}
                      entriesPerPage={false}
                      showTotalEntries={false}
                      noEndBorder
                    />
                  ) : (
                    <Grid
                      style={{
                        display: "grid",
                        placeItems: "center",
                        margin: "15.7%",
                      }}
                    >
                      <img
                        src="https://cdni.iconscout.com/illustration/premium/thumb/no-data-found-9887654-8019228.png"
                        width={"80px"}
                        height={"80px"}
                        alt=""
                      />
                      <MDTypography {...({} as any)} fontSize="12px">
                        No Lounge found. Please add a Lounge to continue.
                      </MDTypography>
                    </Grid>
                  )
                ) : (
                  <span style={{ fontSize: "15px", margin: "2rem" }}>
                    Loading...
                  </span>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default LoungeManagement;
