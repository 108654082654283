import React from "react";
import { Card, Grid, Tooltip } from "@mui/material";
import DashboardLayout from "../../examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../examples/Navbars/DashboardNavbar";
import { useNavigate } from "react-router-dom";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";

interface IButton {
  title: string;
  url: string;
}

const MainLounge: React.FC = () => {
  const navigate = useNavigate();

  const handleButtonClick = (url: string, type: string) => {
    navigate(url, { state: { type } });
  };

  const buttons: IButton[] = [
    { title: "View Lounge", url: "/lounge" },
    { title: "View Bookings", url: "/lounge-management/booking" },
  ];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox {...({} as any)} pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-start">
          <Grid item xs={12}>
            <Card>
              <MDBox
                {...({} as any)}
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography {...({} as any)} variant="h6" color="white">
                  Lounge Management
                </MDTypography>
                <MDBox {...({} as any)} className="ticket_btnsHeaderCont">
                  <Grid display="flex" gap={2}>
                    {buttons.map(({ title, url }, index) => (
                      <Tooltip
                        key={index}
                        title={`Click to ${title.toLowerCase()}`}
                      >
                        <MDButton
                          {...({} as any)}
                          onClick={() => handleButtonClick(url, "add")}
                          variant="outlined"
                          color="white"
                        >
                          {title}
                        </MDButton>
                      </Tooltip>
                    ))}
                  </Grid>
                </MDBox>
              </MDBox>
              <MDBox {...({} as any)} pt={3}>
                <Grid
                  style={{
                    display: "grid",
                    placeItems: "center",
                    margin: "14.8%",
                  }}
                >
                  <img
                    src="https://assets.website-files.com/58c82c3f95d009d84db1e1b8/5efa33dd21cb206cea17b177_wLbP9XIX7twODunMDPAtcfh0V3EGgUxCNsS1u1CKmhIXpW3p8NzGb3mmNFQOlEGii18RAjfy743bbkpc12Mxb8HbUvaKj9xR7tevkbZ_whMKqID2I_h6pBVD0gbiXiOVB610kuCSPhytYhiydw.jpeg"
                    // width={"80px"}
                    height={"100px"}
                    alt=""
                  />
                  <MDTypography {...({} as any)} fontSize="12px">
                    Tap on above buttons to navigate further.
                  </MDTypography>
                </Grid>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
    </DashboardLayout>
  );
};

export default MainLounge;
