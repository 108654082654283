import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { usePosition } from "use-position";

interface mapConfig {
  gestureHandling: string;
  options: {
    fullscreenControl: boolean;
  };
  mapContainerStyle: {};
}

interface MapComponentProps {
  onPositionChange: (position: string) => void;
  storedMapLocation?: {
    lat: number;
    lng: number;
  };
}

const defaultMapConfig: mapConfig = {
  gestureHandling: "greedy",
  options: {
    fullscreenControl: false,
  },
  mapContainerStyle: {
    height: "400px",
    width: "100%",
  },
};

const MapComponent: React.FC<MapComponentProps> = ({
  onPositionChange,
  storedMapLocation,
}) => {
  const [currentPosition, setCurrentPosition] = useState({ lat: 0, lng: 0 });
  const [currentZoom, setCurrentZoom] = useState(2);
  const { latitude, longitude, error } = usePosition(false);
  const location = useLocation();
  const type = location?.state?.type;

  const handleMapClick = (e: google.maps.MapMouseEvent) => {
    const { latLng } = e;
    if (latLng) {
      // console.log("latLng", latLng);
      const clickedLat = latLng.lat();
      const clickedLng = latLng.lng();
      setCurrentPosition({ lat: clickedLat, lng: clickedLng });

      setCurrentZoom(13);
      onPositionChange(JSON.stringify({ lat: clickedLat, lng: clickedLng }));
    }
  };

  useEffect(() => {
    if (latitude && longitude && !error && type === "add") {
      setCurrentPosition({ lat: latitude, lng: longitude });
      setCurrentZoom(13);
      onPositionChange(JSON.stringify({ lat: latitude, lng: longitude }));
    }
  }, [latitude, longitude, error, type]);

  useEffect(() => {
    if (storedMapLocation && storedMapLocation.lat && storedMapLocation.lng) {
      setCurrentPosition(storedMapLocation);
      setCurrentZoom(13);
    }
  }, [storedMapLocation]);

  // console.log("MapComponent storedMapLocation", storedMapLocation);
  // console.log("map currentPosition", currentPosition);

  return (
    <>
      <LoadScript
        // googleMapsApiKey={"AIzaSyDkvQJlfZAN1kbxoIYyyfS0SIZo0haV-rU"}
        googleMapsApiKey={"AIzaSyAn-Li3RxWgTzmBfHxCTGxcjunpj1VcIGk"}
        

        loadingElement={<div />}
        // containerElement={<div />}
        // mapElement={<div />}
      >
        <GoogleMap
          {...defaultMapConfig}
          // defaultCenter={{ lat: 0, lng: 0 }}
          center={currentPosition}
          zoom={currentZoom}
          onClick={handleMapClick}
        >
          {currentPosition && (
            <Marker position={currentPosition} title="Location" />
          )}
        </GoogleMap>
      </LoadScript>
    </>
  );
};
export default MapComponent;
